import React, { useContext, useState, createContext, useEffect } from "react"
import { Global, css, keyframes } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"
import tw from "tailwind.macro"
import "./layout.css"
import {
  beige,
  brownLight,
  black,
  green,
  cream,
  brown,
  brownDark,
} from "../style"
import { ShopifyProvider } from "./shopify"

let PageContext = createContext({})

export let usePage = () => useContext(PageContext)

export default function Layout({ children }) {
  let data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  let [loading, setLoading] = useState(false)
  let [context, setContext] = useState({
    setLoading,
    loading,
  })

  useEffect(() => {
    setContext({
      ...context,
      loading,
    })
  }, [loading])

  return (
    <PageContext.Provider value={context}>
      <Global
        styles={{
          body: {
            backgroundColor: cream,
            minHeight: "100vh",
            width: "100vw",
          },
        }}
      />
      <ShopifyProvider>
        <div
          css={{
            display: "flex",
            minHeight: "100vh",
            flexDirection: "column",
          }}
        >
          {loading && <ProgressBar />}
          <div css={tw`mt-4 flex justify-center`}>
            <h1
              css={{
                ...tw`text-3xl mt-0 mb-2`,
                color: brownLight,
              }}
            >
              january marché
            </h1>
          </div>
          <main
            css={{
              ...tw`mx-auto flex w-screen flex-col`,
              maxWidth: "512px",
              flex: 1,
            }}
          >
            <div css={tw`m-4`}>{children}</div>
          </main>
          <footer
            css={{
              ...tw`mx-auto flex w-screen flex-col`,
              maxWidth: "512px",
              textAlign: "center",
              marginBottom: "2rem",
            }}
          >
            <a
              target="_blank"
              css={{
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
                fontSize: "1.25rem",
                color: brown,
              }}
              href="http://instagram.com/januarymarche"
            >
              @januarymarche
            </a>
            <div
              css={{
                marginTop: "0.5rem",
                fontSize: "0.8rem",
                color: "#888",
              }}
            >
              © 2020 january marché. all rights reserved.
            </div>
          </footer>
        </div>
      </ShopifyProvider>
    </PageContext.Provider>
  )
}

function ProgressBar() {
  let [progress, setProgress] = useState(0)
  let [increment, setIncrement] = useState(30)
  let [delay, setDelay] = useState(10)
  useEffect(() => {
    if (progress >= 100) {
    } else if (progress >= 98) {
      setProgress(100)
    } else {
      setTimeout(() => {
        setProgress(progress + increment)
        setDelay(delay + 100)
        setIncrement(increment * 0.4)
      }, delay)
    }
  }, [progress])
  return (
    <div
      css={{
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1000,
        fontSize: "1.25rem",
      }}
    >
      <div css={{ width: "100vw", height: "7px" }}>
        <div
          css={{
            backgroundColor: green,
            height: "100%",
            width: `${progress}%`,
            transition: `width 300ms ease-in`,
          }}
        ></div>
      </div>
    </div>
  )
}
