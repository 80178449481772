import Client from "shopify-buy"
import React, {
  useRef,
  createContext,
  useState,
  useContext,
  useEffect,
} from "react"

let ShopifyContext = createContext({})

export let useShopify = () => useContext(ShopifyContext)

export function ShopifyProvider({ children }) {
  let client = useRef(
    Client.buildClient({
      domain: "checkout.january.supply",
      storefrontAccessToken: process.env.GATSBY_SHOPIFY_TOKEN,
    })
  )
  let [checkout, setCheckout] = useState()
  useEffect(() => {
    client.current.checkout.create().then(checkout => setCheckout(checkout))
  }, [client])

  async function addLineItem(id) {
    if (!checkout || !client.current) {
      return false
    }
    let lineItem = {
      variantId: id,
      quantity: 1,
    }
    let updatedCheckout = await client.current.checkout.addLineItems(
      checkout.id,
      [lineItem]
    )
    setCheckout(updatedCheckout)
    return updatedCheckout
  }

  async function quickBuyItem(id) {
    let updatedCheckout = await addLineItem(id)
    window.location.href = updatedCheckout.webUrl
  }

  let [context, setContext] = useState({})

  useEffect(() => {
    setContext({
      client: client.value,
      checkout,
      addLineItem,
      quickBuyItem,
    })
  }, [client, checkout])

  return (
    <ShopifyContext.Provider value={context}>
      {children}
    </ShopifyContext.Provider>
  )
}
